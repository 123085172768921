import React, { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import Classes from "../PrivacyPolicy/PrivacyPolicy.module.css";

const style = {
  position: "absolute",
  outline: "none",
  width: "100%",
  height: "90%",
  bottom: 0,
  bgcolor: "background.paper",

  boxShadow: 24,
  overflowY: "auto",
  p: 4,
};

const desktopStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 660,
  height: "95%",
  bgcolor: "background.paper",

  boxShadow: 24,
  overflowY: "auto",
  outline: "none",
  p: 4,
};

export default function PrivacyModal() {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <div>
      <button
        onClick={handleOpen}
        style={{
          backgroundColor: "transparent",
          border: "none",
          padding: "5px",
        }}
      >
        <p
          style={{
            color: "rgba(24, 119, 242, 1)",
            padding: "4px 0px",
            fontSize: "12px",
            textDecoration: "underline",
            textUnderlineOffset: "2px",
          }}
        >
          Privacy & policy
        </p>
      </button>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={window.innerWidth > 768 ? desktopStyle : style}>
          <Typography>
            <div className="" style={{ padding: "10px" }}>
              <div>
                <div className={Classes.Main}>
                  <h1 className={Classes.Title} style={{ fontWeight: "600" }}>
                    Privacy Policy
                  </h1>
                </div>
                <div className={Classes.Conten}>
                  <div className={Classes.Description}>
                    <p>
                      Welcome to swadiamonds.com. This section pertains to the
                      Privacy Policy of the website. We would like to inform
                      that our privacy policy is subject to change without
                      intimation and you shall be required to review the same
                      regularly. The protection and security of your personal
                      information is one of Swa diamonds top priorities. This
                      Privacy Policy discloses Swa diamonds practice with
                      respect to the information collected from the users of the
                      Website or applications. By using the Website or
                      applications you agree to accept the terms of this Privacy
                      Policy as well as the Website and applications' Terms of
                      Use. By accessing or using the Website and applications
                      you expressly consent to our use and disclosure of your
                      personal information in any manner described in this
                      Privacy Policy. This Privacy Policy extends to both, users
                      who visit the Website or applications but do not transact
                      business on the Website or applications as well as users
                      who are registered on the Website or applications.
                      "Personal Information" refers to any information that
                      identifies or can be used to identify, contact or locate
                      the person, to whom such information pertains including,
                      but not limited to, name, address, phone number, fax
                      number, email address, financial profiles, identification
                      number, credit card information etc.
                    </p>

                    <div className={Classes.subHeading}>
                      PLEASE READ THE FOLLOWING TERMS OF OUR PRIVACY POLICY
                      PERSONAL INFORMATION COLLECTED
                    </div>
                    <p>
                      By accepting this privacy policy, you authorize Swa
                      Diamonds to collect, store and use any information that
                      you provide on our Website or applications. The
                      information collected by us are, all information entered
                      by you on our website and applications such as your name,
                      address, contact number, email ID , chat history , and
                      such other information sent by you via emails to our email
                      ID. Information collected by means of cookies that are
                      installed on your hard drive. Information such as the IP
                      Address of your computer, the server from which you are
                      accessing our website and applications, details of web
                      browser and operating system used to access our website or
                      applications, date, time and place of accessing of our
                      website and applications etc.,
                    </p>
                    <div className={Classes.subHeading}>COOKIES</div>
                    <p>
                      Cookies are small pieces of information saved by your
                      browser onto your computer. Cookies are used to record
                      various aspects of your visit and assist swadiamonds.com
                      to provide you with uninterrupted service. swadiamonds.com
                      does not use cookies to save Personal Information for
                      outside uses.<br></br>
                      We have implemented Google Analytics features based on
                      Display Advertising ( Google Display Network Impression
                      Reporting, the DoubleClick Campaign Manager integration,
                      and Google Analytics Demographics and Interest Reporting).
                      Visitors can opt-out of Google Analytics for Display
                      Advertising and customize Google Display Network ads using
                      the Ads Settings.
                      <br />
                      We, along with third-party vendors, including Google, use
                      first-party cookies (such as the Google Analytics cookies)
                      and third-party cookies (such as the DoubleClick cookie)
                      together to report how our ad impressions, other uses of
                      ad services, and interactions with these ad impressions
                      and ad services are related to visits to our site.
                      <br />
                      No use or services available on the website and
                      applications are directed towards children.
                      swadiamonds.com does not knowingly collect personal
                      Information from children or sell off its products to
                      children.
                    </p>
                    <div className={Classes.subHeading}>
                      USE OF INFORMATION COLLECTED
                    </div>
                    <p>
                      Swa Diamonds owns all the information collected via the
                      Website or applications installed on the website or
                      applications. As applicable, the information collected by
                      Swa Diamonds shall be used to contact you about the
                      Website or applications and related news and Services
                      available on the Website or applications; to monitor and
                      improve the Website or applications; calculate the number
                      of visitors to the Website or applications and to know the
                      geographical locations of the visitors; update you on all
                      the special offers available on the Website or
                      applications and provide you with a better shopping
                      experience. This includes sending emails intimating the
                      various offers on the website or applications. You may at
                      any time choose to unsubscribe from such email. Some of
                      your information may be shared with and Used by third
                      parties who shall need to have access to Information, such
                      as courier companies, credit card processing companies,
                      vendors etc. to enable them and swadiamonds.com perform
                      their duties and fulfill your order requirements.
                      swadiamonds.com does not allow any unauthorized persons or
                      organization to use any information that swadiamonds.com
                      may collect from you through the Website and applications.
                      However, swadiamonds.com and our applications are not
                      responsible for any information collected or shared or
                      used by any other third party website or mobile
                      applications due to your browser settings.
                      swadiamonds.com, reserves the right to share any of your
                      personal information to comply with the orders of
                      subpoenas, court orders or other legal process. Your
                      Personal Information may be disclosed pursuant to such
                      subpoenas, court order or legal process, which shall be
                      without notice to you. swadiamonds.com may share
                      collective information such as demographics and Website or
                      mobile application usage statistics with our sponsors,
                      advertisers or other third parties (such third parties do
                      not include swadiamonds.com's marketing partners and
                      network providers). When this type of information is
                      shared, such parties do not have access to your Personal
                      Information. When you contact swadiamonds.com through any
                      means such as chat/ email, swadiamonds.com reserves the
                      right to include your email ID for marketing
                      communications. You can unsubscribe from such
                      communications anytime you wish to do so. The Website and
                      applications may contain links which may lead you to other
                      websites or other mobile applications. Please note that
                      once you leave our website or applications you will be
                      subjected to the Privacy Policy of the other website,
                      mobile applications and this Privacy Policy will no longer
                      apply.
                    </p>
                    {/* <p className={Classes.Highlighted}>
              BY USING THE WEBSITE OR APPLICATIONS, YOU SIGNIFY YOUR AGREEMENT
              TO THE TERMS OF THIS PRIVACY POLICY. swadiamonds.com RESERVES THE
              RIGHT, IN OUR SOLE DISCRETION, TO CHANGE, MODIFY, ADD OR DELETE
              PORTIONS OF THE TERMS OF THIS PRIVACY POLICY AT ANY TIME.
            </p>
            <p>
              If you have any questions about this Privacy Policy, please feel
              free to call us @ 1-800-4250-4949 or write to us at
              customerservice@swadiamonds.com.
            </p> */}
                    <div className={Classes.subHeading}>
                      BY USING THE WEBSITE OR APPLICATIONS, YOU SIGNIFY YOUR
                      AGREEMENT TO THE TERMS OF THIS PRIVACY POLICY.
                      swadiamonds.com RESERVES THE RIGHT, IN OUR SOLE
                      DISCRETION, TO CHANGE, MODIFY, ADD OR DELETE PORTIONS OF
                      THE TERMS OF THIS PRIVACY POLICY AT ANY TIME.
                    </div>
                    <p>
                      Swa Diamonds owns all the information collected via the
                      Website or applications installed on the website or
                      applications. As applicable, the information collected by
                      Swa Diamonds shall be used to contact you about the
                      Website or applications and related news and Services
                      available on the Website or applications; to monitor and
                      improve the Website or applications; calculate the number
                      of visitors to the Website or applications and to know the
                      geographical locations of the visitors; update you on all
                      the special offers available on the Website or
                      applications and provide you with a better shopping
                      experience. This includes sending emails intimating the
                      various offers on the website or applications. You may at
                      any time choose to unsubscribe from such email. Some of
                      your information may be shared with and Used by third
                      parties who shall need to have access to Information, such
                      as courier companies, credit card processing companies,
                      vendors etc. to enable them and swadiamonds.com perform
                      their duties and fulfill your order requirements.
                      swadiamonds.com does not allow any unauthorized persons or
                      organization to use any information that swadiamonds.com
                      may collect from you through the Website and applications.
                      However, swadiamonds.com and our applications are not
                      responsible for any information collected or shared or
                      used by any other third party website or mobile
                      applications due to your browser settings.
                    </p>
                    <p>
                      swadiamonds.com, reserves the right to share any of your
                      personal information to comply with the orders of
                      subpoenas, court orders or other legal process. Your
                      Personal Information may be disclosed pursuant to such
                      subpoenas, court order or legal process, which shall be
                      without notice to you.
                    </p>
                    <p>
                      swadiamonds.com may share collective information such as
                      demographics and Website or mobile application usage
                      statistics with our sponsors, advertisers or other third
                      parties (such third parties do not include
                      swadiamonds.com's marketing partners and network
                      providers). When this type of information is shared, such
                      parties do not have access to your Personal Information.
                      When you contact swadiamonds.com through any means such as
                      chat/ email, swadiamonds.com reserves the right to include
                      your email ID for marketing communications. You can
                      unsubscribe from such communications anytime you wish to
                      do so.
                    </p>
                    <p className={Classes.Para13Privacy}>
                      The Website and applications may contain links which may
                      lead you to other websites or other mobile applications.
                      Please note that once you leave our website or
                      applications you will be subjected to the Privacy Policy
                      of the other website, mobile applications and this Privacy
                      Policy will no longer apply.
                    </p>
                    <div
                      style={{ display: "flex", justifyContent: "center" }}
                    >
                      <button
                        className={Classes.BtnAgreeAccept}
                        onClick={handleClose}
                      >
                        Agree & accept
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Typography>
        </Box>
      </Modal>
    </div>
  );
}
